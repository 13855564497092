<template>
  <label v-if="showButton" :class="buttonClass">
    <slot name="button">Upload</slot>
    <input
      ref="fileInput"
      type="file"
      class="w-0 h-0 overflow-hidden"
      @change="uploadFile"
    />
  </label>
  <div v-bind="$attrs" ref="dropZoneRef" class="relative">
    <slot
      name="default"
      :selectFile="selectFile"
      :isOverDropZone="isOverDropZone"
      :uploader="uploader"
    ></slot>
  </div>
</template>

<script setup lang="ts">
import type { FIleUploadType } from "@/types/formData";

const props = defineProps<{
  multiple?: boolean;
  max?: number;
  uploadType?: FIleUploadType;
  mimeTypes?: string[];
  buttonClass?: string;
  showButton?: boolean;
}>();

const dropZoneRef = ref<HTMLDivElement>();
const uploader = useS3Uploader({
  mimeTypes: props.mimeTypes,
  uploadType: props.uploadType,
});
const fileInput = ref<HTMLInputElement>();

const emit = defineEmits<{
  (e: "uploadSuccess", data: (typeof uploader)["uploadState"]["value"][0]): void;
}>();

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: uploader.handleFileInput,
});

function selectFile() {
  if (fileInput.value) {
    fileInput.value.click();
  }
}

function uploadFile(event: Event) {
  const fileList = (event.target as HTMLInputElement).files;
  if (fileList) {
    const files = Array.from(fileList);
    uploader.handleFileInput(files);
  }
}

defineExpose({
  uploader,
  selectFile,
  isOverDropZone,
});

watch(uploader.uploadRecently, newUploads => {
  if (newUploads.length) {
    const processedIds = newUploads.map(item => {
      emit("uploadSuccess", item);
      return item.id;
    });

    nextTick(() => {
      uploader.uploadRecently.value = uploader.uploadRecently.value.filter(
        item => !processedIds.includes(item.id)
      );
    });
  }
});
</script>
