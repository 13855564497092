export function useFakeProgress(options?: {
  auto?: boolean;
  interval?: number;
  maxValue?: number;
  minValue?: number;
  increments?: number;
}) {
  const {
    interval = 2000,
    increments = 10,
    auto = true,
    minValue = 0,
    maxValue = 100,
  } = options || {};
  const progress = ref(minValue);
  const intervalId = ref();

  function startProgress() {
    intervalId.value = setInterval(() => {
      let newValue = progress.value + Math.floor(Math.random() * increments) + 1;
      if (newValue >= maxValue) {
        newValue = maxValue;
      }
      progress.value = newValue;
    }, interval);
  }

  function endProgress() {
    clearInterval(intervalId.value);
    intervalId.value = null;
  }

  if (auto) {
    onMounted(startProgress);
    onBeforeUnmount(endProgress);
  }

  return {
    progress,
    startProgress,
    endProgress,
  };
}
