/* eslint-disable prefer-const */
import type { RouteLocationNormalized } from "vue-router";

class AuthError extends Error {
  error: string;
  constructor(message: string) {
    super(message);
    this.name = "AuthError";
    this.error = "";
  }
}

const allowedRoutes = ["/restricted", "/register", "/third-party", "/login", "/logout"];
const invalidRedirectUrls = ["/", "/init", "/error"];

export async function accessTokenMiddleware(to: RouteLocationNormalized) {
  const scope = effectScope();
  try {
    const redirectUrl = await scope.run(async () => {
      if (allowedRoutes.includes(to.path)) return true;
      const router = useRouter();
      const { getAccessTokenSilently, isAuthenticated, idTokenClaims } = useCurrentUser();

      const getTokenFromDesktop = window.electronAPI?.getAccessToken;

      const { pathname = "", search = "" } = window.location;
      let { path: redirect, query } = router.resolve(pathname + search);
      const accountSwitched = sessionStorage.getItem("MM-accountSwitched");
      if (accountSwitched) sessionStorage.removeItem("MM-accountSwitched");

      // has token and claims
      if (isAuthenticated.value && appAuthToken.value) return true;

      // get new token
      if (!appAuthToken.value) {
        currentSessionInformation.initializingSession = true;
        const token = isDESKTOP
          ? await getTokenFromDesktop()
          : await getAccessTokenSilently({
              authorizationParams: {
                audience: env.VITE_AUTH0_TOKEN_AUDIENCE,
              },
            });
        if (token) appAuthToken.value = token;
        if (isDESKTOP) {
          const claims = await window.electronAPI.getProfile();
          if (claims) setCurrentSessionClaims(claims);
        } else {
          if (idTokenClaims.value) setCurrentSessionClaims(idTokenClaims.value);
        }

        // redirect to /init
        if (isDESKTOP || accountSwitched) redirect = "tasks/recent";
        const redirectUrl = invalidRedirectUrls.includes(redirect) ? undefined : redirect;
        return {
          path: "/init",
          query: {
            redirectUrl,
            ...query,
          },
        };
      }

      if (to.path === "/error") {
        return appAuthToken.value ? "/init" : true;
      }

      if (appAuthToken.value && to.path === "/error") return "/init";
      return true;
    });
    return redirectUrl;
  } catch (error) {
    if ((error as AuthError)?.error === "login_required") {
      return to.path === "/login" || "/login";
    }
    return to.path === "/error" || "/error";
  } finally {
    scope.stop();
  }
}
