<template>
  <div class="flex flex-col pt-2">
    <div class="ma-label">
      <slot name="label">
        <label v-if="label">{{ label }}</label>
      </slot>
    </div>
    <div class="relative pb-2">
      <div class="relative">
        <slot :validator="validator" :handleBlur="handleBlur"></slot>
        <div class="absolute top-3.5 right-2">
          <AppTooltip
            size="sm"
            position="left"
            :bg="'dark'"
            v-if="validator.value.$error"
          >
            <p class="text-xs sm:whitespace-nowrap">
              {{ validator.value.$errors[0]?.$message }}
            </p>
          </AppTooltip>
        </div>
      </div>
      <small
        v-if="validator.value.$error"
        class="text-red-500 absolute -bottom-2.5 text-xs"
      >
        {{ validator.value.$errors[0]?.$message }}</small
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import AppTooltip from "@/components/shared/AppTooltip.vue";
import useVuelidate from "@vuelidate/core";
import { ulid } from "ulid";
import { required as fieldRequired } from "@vuelidate/validators";
import type { DropdownOption } from "@/types/formData";

const props = withDefaults(
  defineProps<{
    type?: "email" | "text" | "phone" | "url" | "password";
    label?: string;
    value: string | number | Date | DropdownOption | any[];
    disabled?: boolean;
    required?: boolean;
    readonly?: boolean;
    placeholder?: string;
    rules?: Record<string, any>;
  }>(),
  {
    id: ulid(),
    disabled: false,
    placeholder: "",
    value: "",
    type: "text",
    rules: () => ({}),
  }
);

const validator = useVuelidate(
  // @ts-ignore
  { value: { ...props.rules, ...(props.required ? { required: fieldRequired } : {}) } },
  computed(() => ({ value: props.value }))
);

function handleBlur() {
  validator.value.value.$touch();
}
</script>
