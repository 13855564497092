<template>
  <div
    class="p-4 sm:p-6 shadow-md bg-white dark:bg-surface-800 rounded-lg max-w-lg mx-auto"
  >
    <p class="h4 mb-4 mt-4">{{ $t("didYouKnow") }}</p>
    <Transition
      mode="out-in"
      enter-from-class="opacity-0 translate-y-10"
      leave-to-class="opacity-0"
      enter-active-class="duration-300"
      leave-active-class="duration-200"
    >
      <DragToCreateTask v-if="idx === 0" />
      <EditKnowledge v-else-if="idx === 1" />
      <GetHelpOnTopics v-else />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import DragToCreateTask from "./DragToCreateTask.vue";
import GetHelpOnTopics from "./GetHelpOnTopics.vue";
import EditKnowledge from "./EditKnowledge.vue";

const intervalId = ref();
const idx = ref(0);

onBeforeMount(() => {
  intervalId.value = setInterval(() => {
    idx.value = (idx.value + 1) % 2;
  }, 5000);
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
  intervalId.value = null;
});
</script>
