<template>
  <div class="absolute">
    <slot></slot>
    <teleport to="#teleport-connection">
      <span v-if="status === 'OPEN'" class="flex items-center"
        ><SocketConnected
          class="h-3 w-3 text-green-600 mr-1 MM-socket-connected__status"
        ></SocketConnected
        >{{ $t("Connected") }}</span
      >
      <AppTooltip size="sm" v-else-if="status === 'CLOSED'" position="top">
        <template #trigger>
          <span @click="open" class="flex items-center"
            ><SocketError
              class="h-3 w-3 text-red-600 rotate-45 mr-1 MM-socket-disconnected__status"
            ></SocketError
            >{{ $t("Disconnected") }}</span
          >
        </template>
        <span>{{ $t("SocketDisconnectTooltip") }}</span>
      </AppTooltip>
      <span v-else-if="status === 'CONNECTING'" class="flex items-center"
        ><SocketConnecting
          class="h-3 w-3 text-yellow-400 dark:text-yellow-500 mr-1"
        ></SocketConnecting
        >{{ $t("Connecting") }}...</span
      >
    </teleport>
    <!-- Notifications -->
    <JobNotificationList
      v-if="jobsStore.isNotificationPanelOpen"
      class=""
      @close="jobsStore.closeNotificationPanel()"
    />
  </div>
</template>

<script setup lang="ts">
/* eslint-disable no-case-declarations */
import SocketConnected from "@/components/icons/SocketConnected.vue";
import SocketConnecting from "@/components/icons/SocketConnecting.vue";
import SocketError from "@/components/icons/SocketError.vue";
import type { ITaskBase } from "@/types/task";
import { cloneDeep } from "lodash-es";
import AppTooltip from "@/components/shared/AppTooltip.vue";
import type {
  OngoingJob,
  TaskJobData,
  SocketData,
  AssistantJobSocketData,
  ConversationJobData,
  FileProcessedJobData,
  EmailJobData,
} from "@/types/jobs";
import JobNotificationList from "@/components/jobs/JobNotificationList.vue";
const { addEmailProcessingJob, addFileUploadJob } = useInboxWebsocketJobs();

type TaskData = { pages: { items: ITaskBase[]; next?: string | undefined }[] };

const { t } = withI18nHelpers();
const queryClient = useQueryClient();
const { mockSocketUrl } = useMockSocketServer();
const route = useRoute();
const session = currentSessionInformation;
const jobsStore = useJobsStore();
const connectionUrl = computed(() => {
  if (USE_MOCK_SOCKET) return mockSocketUrl;
  return session?.appWebsocketApiUrl
    ? `${session?.appWebsocketApiUrl}?authorizationToken=${session?.appAuthToken}`
    : undefined;
});

const { status, open } = useWebSocket(connectionUrl, {
  autoReconnect: {
    retries: 3,
    delay: 3000,
    onFailed() {
      console.error("WebSocket connection failed after 3 retries.");
    },
  },
  heartbeat: {
    message: "",
    interval: 30_000,
  },
  onMessage(_, event) {
    onRefresh(event.data);
  },
});

function onRefresh(message: string) {
  const parseMessage: SocketData = maybeParseJson(message);
  const { action, data: _data } = parseMessage;
  switch (action) {
    case "TaskUpdate":
      const data = _data as TaskJobData;
      const queryKey = ["task", { id: data.taskId }];
      queryClient.invalidateQueries({ queryKey });
      //Mutate task list
      if (!data.currentStatus && !data.subTaskId) return;
      const taskList: TaskData | undefined = queryClient.getQueryData([
        "tasks",
        { limit: taskListLimit },
      ]);
      if (!taskList?.pages) return;
      const newData = cloneDeep(taskList);
      newData.pages = newData?.pages?.map(page => {
        if (!page.items) return page;
        page.items = page?.items?.map(task => {
          if (task?.id !== data.taskId) return task;
          return {
            ...task,
            [data.currentStatus]: 1,
            running: data.currentStatus === "running" ? 1 : 0,
          };
        });
        return page;
      });
      queryClient.setQueryData(["tasks", { limit: taskListLimit }], newData);
      if (
        data.currentStatus === "complete" &&
        (route.name !== "single-task" || route.params.id !== data.taskId)
      ) {
        jobsStore.handleJobEvent(formatTaskCompleteJob(data.taskId));
      }
      break;
    case "FileUploadStatus":
      if ((_data as FileProcessedJobData).fileId) {
        addFileUploadJob(_data as FileProcessedJobData);
      }
      break;
    case "NotificationIntentStatus":
      addEmailProcessingJob(_data as EmailJobData);
      break;
    case "AssistantMessage":
      handleV2ConversationMessageEvent(_data as ConversationJobData);
      break;
    case "JobProcessingUpdate":
      maybeShowJobProcessingNotification(_data as AssistantJobSocketData);
      break;
    case "JobProcessingComplete":
      maybeShowJobProcessingNotification(_data as AssistantJobSocketData);
      if (
        [
          "account_discover_website",
          "process_website",
          "account_discover_competitor",
          "ProcessSocialMedia",
        ].includes((_data as AssistantJobSocketData).jobType)
      ) {
        const data = _data as AssistantJobSocketData;
        handleDiscoveryJob(data, queryClient);
      }
      break;
    default:
      break;
  }
}

function maybeShowJobProcessingNotification(job: AssistantJobSocketData) {
  if (
    [
      "process_website",
      "crawl_website",
      "crawl_website_page",
      "crawl_website",
      "ProcessSocialMedia",
    ].includes(job?.jobType)
  ) {
    // @ts-ignore -- job is not the correct type
    const data = job as OngoingJob;
    // crawl complete
    if (job.status === "complete" && job.jobType === "crawl_website") {
      jobsStore.handleJobEvent(formatWebsiteCrawlComplete(job));
      return;
    }
    // ongoing
    if (!data.data?.total) return;
    jobsStore.handleJobEvent({
      ...data,
      title: t("processingPages"),
    });
  }
}
</script>
