import type { SocialPlatform } from "@/types/account";
import isUrl from "validator/lib/isURL";
import SocialLinks from "social-links";

export function isValidUrl(urlString: string, requireProtocol = false): boolean {
  if (typeof urlString !== "string" || !urlString) return false;
  return isUrl(urlString, {
    require_protocol: requireProtocol,
  });
}

export function removeTrailingSlash(url: string) {
  if (!url || url[url.length - 1] !== "/") return url;
  return url.slice(0, url.length - 1);
}

export function isValidUrlV2(url: string) {
  try {
    return typeof new URL(url).href === "string";
  } catch (e) {
    return false;
  }
}

export function unTrailingSlashIt(str?: string) {
  if (str?.endsWith("/")) {
    return str.substring(0, str.length - 1);
  }
  return str || "";
}

export function unLeadingSlashIt(str?: string) {
  if (str?.startsWith("/")) {
    return str.substring(1);
  }
  return str || "";
}

export function trailingSlashIt(str?: string) {
  if (!str) {
    return "/";
  }

  if (str?.endsWith("/")) {
    return str || "";
  }
  return `${str}/`;
}

export function isValidSocialUrl(urlString: string, platform: SocialPlatform) {
  const twitterRegex = /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9_]{1,50}\/?$/i;
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/[a-zA-Z0-9_]{1,50}\/?$/i;
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?(instagram)\.com\/[a-zA-Z0-9_.]{1,50}\/?$/i;
  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/(company|in)\/([a-zA-Z0-9_-]+\/?)?$/;
  const threadsRegex = /^(https?:\/\/)?(www\.)?threads\.net\/@[\w.-]+\/?$/;

  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/@[a-zA-Z0-9_-]+\/?$/;

  const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[a-zA-Z0-9_.]{1,24}\/?$/i;
  const emptyRegex = /n\/a/i;

  if (emptyRegex.test(urlString)) {
    return true;
  }
  switch (platform) {
    case "twitter":
      return twitterRegex.test(urlString);
    case "facebook":
      return facebookRegex.test(urlString);
    case "instagram":
      return instagramRegex.test(urlString);
    case "linkedin":
      return linkedinRegex.test(urlString);
    case "threads":
      return threadsRegex.test(urlString);
    case "tiktok":
      return tiktokRegex.test(urlString);
    case "youtube":
      return youtubeRegex.test(urlString) || validateSocialLink(urlString, "youtube");
      return false;
  }
}

export function customWebsitesValidator() {
  return (value?: any) => {
    if (value && value !== "n/a") {
      return isValidUrl(value);
    } else {
      return true;
    }
  };
}

function getHostName(url: string) {
  try {
    let hostname = new URL(url)?.hostname ?? "";
    if (hostname.startsWith("www.")) hostname = hostname.replace("www.", "");
    return hostname;
  } catch {
    return "";
  }
}

/**
 * Checks if any of the competitor URLs have the same domain as the new URL.
 *
 * @param {string} url1 - A string containing a single new URL.
 * @param {string} url2 - Another URL to compare.
 * @return {boolean} True if the urls have  the same domain name.
 */
export function haveSameDomain(url1: string, url2: string): boolean {
  if (url1 === url2) return true;
  if (!url1 || !url2) {
    return false;
  }

  const url1HostName = getHostName(formatUrl(url1));
  if (!url1HostName) return false;

  const url2HostName = getHostName(formatUrl(url2));
  if (!url2HostName) return false;

  return url1HostName === url2HostName;
}

const validateSocialLink = (url: string, platform: SocialPlatform): boolean => {
  const socialLinks = new SocialLinks();

  return isValidUrl(url) && socialLinks.isValid(platform, url);
};
