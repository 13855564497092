<template>
  <svg
    width="347"
    height="200"
    viewBox="0 0 347 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="246.5"
      y="0.5"
      width="100"
      height="197"
      class="stroke-surface-300 dark:stroke-surface-600"
    />
    <path
      d="M20.9689 104.012C11.1973 109.155 132.785 179.066 238.432 93.4921"
      stroke-width="2"
      stroke-dasharray="4 4"
      class="stroke-surface-300 dark:stroke-surface-600"
    />
    <path
      d="M241.024 99.3947L241.311 92.0709L234.278 89.1676"
      stroke-width="2"
      stroke-linecap="round"
      class="stroke-surface-300 dark:stroke-surface-600"
    />
    <path
      d="M51.0833 37.223C74.556 37.223 93.5833 56.2502 93.5833 79.723C93.5833 103.196 74.556 122.223 51.0833 122.223C27.6105 122.223 8.58325 103.196 8.58325 79.723C8.58325 56.2502 27.6105 37.223 51.0833 37.223ZM51.0833 96.723C49.9561 96.723 48.8751 97.1707 48.078 97.9678C47.281 98.7648 46.8333 99.8458 46.8333 100.973C46.8333 102.1 47.281 103.181 48.078 103.978C48.8751 104.775 49.9561 105.223 51.0833 105.223C52.2104 105.223 53.2914 104.775 54.0885 103.978C54.8855 103.181 55.3333 102.1 55.3333 100.973C55.3333 99.8458 54.8855 98.7648 54.0885 97.9678C53.2914 97.1707 52.2104 96.723 51.0833 96.723ZM51.0833 56.348C46.9973 56.348 43.0786 57.9711 40.1894 60.8603C37.3002 63.7496 35.677 67.6682 35.677 71.7542C35.677 72.8814 36.1248 73.9624 36.9218 74.7594C37.7188 75.5564 38.7998 76.0042 39.927 76.0042C41.0542 76.0042 42.1352 75.5564 42.9322 74.7594C43.7292 73.9624 44.177 72.8814 44.177 71.7542C44.1784 70.5006 44.521 69.271 45.168 68.1973C45.8151 67.1236 46.7422 66.2463 47.8499 65.6594C48.9576 65.0725 50.2042 64.7981 51.4559 64.8658C52.7077 64.9334 53.9175 65.3405 54.9555 66.0434C55.9935 66.7462 56.8206 67.7184 57.3482 68.8556C57.8757 69.9928 58.0838 71.2521 57.9501 72.4985C57.8164 73.745 57.346 74.9315 56.5892 75.931C55.8325 76.9304 54.818 77.705 53.6545 78.1717C50.7815 79.3192 46.8333 82.2602 46.8333 87.1605V88.223C46.8333 89.3501 47.281 90.4311 48.078 91.2282C48.8751 92.0252 49.9561 92.473 51.0833 92.473C52.2104 92.473 53.2914 92.0252 54.0885 91.2282C54.8855 90.4311 55.3333 89.3501 55.3333 88.223C55.3333 87.186 55.5458 86.6675 56.4425 86.2255L56.8123 86.0555C60.1307 84.7205 62.8814 82.2728 64.5929 79.1319C66.3043 75.991 66.8698 72.3525 66.1925 68.8403C65.5152 65.3282 63.6372 62.1609 60.8805 59.8817C58.1239 57.6025 54.6602 56.3532 51.0833 56.348Z"
      class="fill-surface-300 dark:fill-surface-600"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
