<template>
  <svg
    width="417"
    height="253"
    viewBox="0 0 417 253"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="192.5"
      width="404"
      height="60"
      rx="7.5"
      class="stroke-surface-300 dark:stroke-surface-600 fill-surface-100 dark:fill-surface-900"
    />
    <path
      d="M326.292 86.625C323.84 86.625 321.741 85.8179 319.997 84.2036C318.252 82.5894 317.378 80.6465 317.375 78.375V20.625C317.375 18.3563 318.249 16.4148 319.997 14.8005C321.744 13.1863 323.843 12.3777 326.292 12.375H388.708C391.16 12.375 393.26 13.1835 395.008 14.8005C396.756 16.4175 397.628 18.359 397.625 20.625V78.375C397.625 80.6437 396.753 82.5866 395.008 84.2036C393.263 85.8206 391.163 86.6277 388.708 86.625H326.292ZM330.75 70.125H384.25L367.531 49.5L354.156 66L344.125 53.625L330.75 70.125Z"
      class="fill-surface-300 dark:fill-surface-600"
    />
    <path
      d="M311.702 49.5038H306.213M311.702 49.5038C311.702 50.4838 309.652 52.3161 308.957 53.0038M311.702 49.5038C311.702 48.5238 309.652 46.6916 308.957 46.0038M287 49.5038H292.489M287 49.5038C287 48.5238 289.051 46.6916 289.746 46.0038M287 49.5038C287 50.4838 289.051 52.3161 289.746 53.0038M296.372 81.0038V79.3588C296.372 78.2245 296.084 77.1205 295.55 76.2123L290.293 67.2716C289.856 66.5296 289.531 65.6283 289.731 64.7358C290.226 62.5326 292.182 60.5743 294.37 64.0236L296.57 67.0126V48.7933C296.705 45.5908 300.933 44.5811 301.37 48.7933V59.1761C303.409 58.8436 313.011 60.6653 311.614 68.3898L311.416 69.5028C311.131 71.1093 310.294 73.9688 309.371 75.6418C308.41 77.3831 308.86 80.1918 308.75 81.0073"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="stroke-primary animate-pulse"
    />
    <path
      d="M323.5 42C331.5 30 160.5 24 90 188.5"
      stroke-width="2"
      stroke-dasharray="4 4"
      class="stroke-surface-300 dark:stroke-surface-600"
    />
    <path
      d="M84 183.254L87.5928 191.926L96.875 190.951"
      stroke-width="2"
      stroke-linecap="round"
      class="stroke-surface-300 dark:stroke-surface-600"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
