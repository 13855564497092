import type { EmailJobData, FileProcessedJobData } from "@/types/jobs";

export type InboxJob = {
  id: string;
  count: number;
  type: "file" | "email";
  scope?: "email" | "logo" | "contacts";
  meta?: Record<string, any>;
};

export const useInboxWebsocketJobs = createSharedComposable(() => {
  const queryClient = useQueryClient();
  const jobs = ref<Record<string, InboxJob>>({});
  const jobList = computed(() => Object.values(jobs.value));
  const emailJobs = computed(() => jobList.value.filter(job => job.type === "email"));
  const logoJobs = computed(() => jobList.value.filter(job => job.scope === "logo"));
  const contactsJobs = computed(() =>
    jobList.value.filter(job => job.scope === "contacts")
  );

  function addEmailProcessingJob(data: EmailJobData) {
    if (!data.subject) return; // not an email job
    if (data.status === "processed") {
      maybeRefetchData("email");
      removeJob(data.notificationIntentId);
      return;
    }
    const found = jobs.value[data.notificationIntentId];
    const modifier = data.status === "processing" ? 0 : -1;
    const delta = (found?.count || 0) + modifier;
    jobs.value[data.notificationIntentId] = {
      id: data.notificationIntentId,
      count: delta,
      type: "email",
      scope: "email",
      meta: { emailAddresses: data.emailAddresses, allContacts: data.allContacts },
    };
  }

  function addFileUploadJob(file: FileProcessedJobData, scope?: InboxJob["scope"]) {
    if (!file.fileId) return;
    const found = jobs.value[file.fileId];
    let type = scope;
    if (found) {
      type = type || found.scope;
      const modifier = file.status === "processing" ? 0 : -1;
      const delta = found.count + modifier;
      if (delta > 0) jobs.value[file.fileId] = { ...found, count: delta };
      else delete jobs.value[file.fileId];
    } else if (file.status === "processing") {
      jobs.value[file.fileId] = { id: file.fileId, count: 1, type: "file", scope };
    }
    if (file.status === "uploaded" || file.status === "processed") {
      maybeRefetchData(type);
      removeJob(file.fileId);
    }
  }

  function removeJob(jobId: string) {
    setTimeout(() => {
      delete jobs.value[jobId];
    }, 10 * SECOND);
  }

  function maybeRefetchData(type?: InboxJob["scope"]) {
    let queryKey: string[] = [];
    if (type === "logo") queryKey = ["email-templates"];
    if (type === "contacts") queryKey = ["email-contacts"];
    if (type === "email") queryKey = ["account", "messages"];
    if (!queryKey.length) return;
    queryClient.invalidateQueries({ queryKey });
  }

  return {
    jobs,
    jobList,
    emailJobs,
    logoJobs,
    contactsJobs,
    addFileUploadJob,
    addEmailProcessingJob,
  };
});
