import type { RouteRecordRaw } from "vue-router";
import { beforeEnterFeatureFlagged } from "./middleware/beforeEnterFeatureFlagged";

export const emailRoutes: RouteRecordRaw[] = [
  {
    path: "inbox",
    component: () => import("@/views/emails/EmailInboxView.vue"),
    children: [
      {
        path: "compose",
        name: RouteNames.EMAIL_GENERAL_COMPOSE,
        component: () => import("@/components/emails/ComposeEmail.vue"),
      },
      {
        path: "",
        name: RouteNames.EMAIL_INBOX,
        component: () => import("@/components/emails/InboxBody.vue"),
      },
    ],
  },
  {
    path: "templates",
    component: () => import("@/views/emails/EmailTemplateView.vue"),
    children: [
      {
        path: ":id",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_SINGLE_TEMPLATE,
        component: () => import("@/components/emails/TemplateBody.vue"),
      },
      {
        path: "",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_TEMPLATES,
        component: () => import("@/components/emails/TemplateBodyEmpty.vue"),
      },
    ],
  },
  {
    path: "contacts",
    component: () => import("@/views/emails/EmailContactsView.vue"),
    children: [
      {
        path: ":id",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_SINGLE_CONTACT,
        component: () => import("@/components/emails/ContactsBody.vue"),
      },
      {
        path: "new",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_ADD_CONTACT,
        component: () => import("@/components/emails/ContactsBody.vue"),
      },
      {
        path: "compose",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_CONTACT_COMPOSE,
        component: () => import("@/components/emails/ComposeEmail.vue"),
      },
      {
        path: "",
        beforeEnter: beforeEnterFeatureFlagged("emailMarketing"),
        name: RouteNames.EMAIL_CONTACTS,
        component: () => import("@/components/emails/ContactBodyEmpty.vue"),
      },
    ],
  },
  {
    path: "",
    name: RouteNames.EMAILS,
    redirect: {
      name: RouteNames.EMAIL_INBOX,
    },
  },
];
