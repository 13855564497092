export const temporaryEn = {
  // add english strings here
  // you do not need to use en: { }, thats assumed
  wordsQueued: "Queued",
  wordsScheduled: "Scheduled",
  wordsRecent: "Recent",
  success: "Success",
  taskScheduledSuccessMessage: "Task scheduled successfully",
  itWillRunOn: "It will run on {date}",
  itWillRunEvery: "It will run every {interval}",
  nextRun: "Next run is on {date}",
  runsEvery: "Runs every {interval}",
  seePendingTasks: "See pending tasks",
  scheduledFor: "Scheduled for {date}",
  editTaskTitle: "Edit Task",
  taskUpdateSuccess: "Task updated successfully",
  timeAm: "AM",
  timePm: "PM",
  pastDateError: "Date must be in the future",
  scheduleThisTask: "Schedule This Task",
  update: "Update",
  regenerate: "Regenerate",
  thumbsDown: "This is bad",
  thumbsUp: "This is good",
  noScheduledTasks: "No scheduled activities",
  noScheduledTasksInfo:
    "Once you schedule a task, it will appear here. Click the button below to get started.",
  wordsImmediate: "Immediate",
  days: "days",
  hours: "hours",
  minutes: "minutes",
  cronExpression: "Cron expression",
  wordsUse: "Use",
  wordsProductsAndServicesPlaceholder: "Your products and Services",
  messages: "Messages",
  wordsOpen: "Open",
  wordsWelcome: "Welcome",
  wordsTakingTooLong: "Taking too long?",
  runningSubTasks: "Running {count} sub-tasks",
  linksUsed: "Links used",
  wordsArticle: "Article",
  following: "Following",
  followers: "Followers",
  joined: "Joined",
  posts: "Posts",
  visitWebsite: "Visit Website",
  wordsIntegrations: "Integrations",
  noActiveMessageText: "Select a message to view its content",
  wordsSystem: "System",
  noMessageResponseText: "Sorry, this message has no response",
  wordsRefreshing: "Refreshing",
  logoutMessage: "Signing out, please wait",
  authErrorMessage: "There was an error signing you in, please try again",
  signedInAs: "Signed in as",
  wordsNoAccount: "No account",
  wordsManageAccounts: "Manage accounts",
  wordsViewAll: "View all",
  wordsSwitchAccount: "Switch account",
  confirmAccountSwitch: "Are you sure you want to switch to {account}?",
  accountSwitched: "Account switched",
  buttonsAddAccount: "Add Account",
  noAccounts: "No accounts",
  accountDeleted: "Account deleted",
  confirmAccountDeletion: "Are you sure you want to delete this account?",
  wordsAccounts: "Accounts",
  addTenantAccountDescription: "Fill the details to add your new account",
  acknowledgeCompanyAuthorized: "I acknowledge that I am authorized by this company",
  startFreeTrial: "Start Free Trial",
  freeTrialTitle: "Start 14 day free trial",
  freeTrialDescription: "Try now & decide later. No credit card required",
  accountFreeTrialInfo: "You're currently on a free trial, upgrade for full access",
  tenantWelcomeMessage: "Let's set up your first account",
  createFirstAccount: "Create your first account",
  accountListDescription: "Add and manage up to {count} accounts",
  tenantBackToAppLink: "Back to {companyName} Marketing Assistant",
  aboutYourCompany: "Tell us about your company",
  moreAboutYourCompany: "Tell us a little more about your company",
  editPost: "Edit Post",
  clearAll: "Clear All",
  onboardingStepError:
    "There was an error getting the required steps. Please click the button to try again",
  wordsOn: "On",
  wordsOff: "Off", // wordsOff,Off
  taskCompleteNotificationTitle: "Completed Tasks",
  taskCompleteNotificationDescription:
    "Be notified through email when a task finishes executing.",
  notificationsUpdated: "Notifications Updated",
  myNotifications: "My Notifications",
  wordsGeneral: "General",
  errorGettingPlans: "There was an error getting the plans, please try again.",
  wordsFiles: "Files",
  wordsAbout: "About",
  wordsSocial: "Social",
  socialInstagram: "Instagram",
  socialLinkedin: "Linkedin",
  notAvailable: "Not available",
  paymentFailedTitle: "Payment failed",
  paymentFailedDescription:
    "Your last payment attempt was unsuccessful. Click the manage subscription button below to manage your subscription",
  onboardingSuccessText: "You're all set",
  invalidSocialUrl: "Invalid {type} url",
  formsName: "Name",
  upload: "Upload",
  uploading: "Uploading",
  uploadFile: "Upload File",
  noFilesTitle: "No files",
  noFilesDescription: "Upload files to get started",
  unAuthorizedError: "You are not authorized to perform this action",
  upgradeToBusinessPlan: "Upgrade to Business plan",
  businessPlan: "Business Plan",
  businessPlanUpgradeSuffix: "and get up to 3 companies in one account",
  whyUpgrade: "Why should you upgrade to the business plan?",
  upgradeReasonIntro: "The business plan gives you:",
  upgradeInfo: "You will be taken to the checkout page to complete the upgrade",
  customPlanUpgradePre: "Switch to a custom plan",
  customPlanUpgradeSuffix: "to increase your account limit",
  seeOptions: "See options",
  customPlans: "Custom Plans",
  customPlansSubtitle: "Your account limit is {limit}, you're upgrading to:",
  maximumCustomPlansLimit: "Maximum limit for custom plans is {count}",
  minimumCustomPlansLimit: "Minimum limit for custom plans is {count}",
  minMaxCustomPlansLimit: "Please select a number between {min} and {max}",
  accountsMoreThanNewLimit: "Plan limit can't be less than the number of active accounts",
  alreadyOnPlanError: "You are already on this plan",
  wordsActive: "Active",
  wordsDismiss: "Dismiss",
  wordsReload: "Reload",
  pwaUpdateMessage: "New content available. Click the reload button to update",
  wordsDowngrade: "Downgrade",
  wordsUpgrade: "Upgrade",
  wordsCompetitorsTitle: "Tell us more about your organization's competitors",
  wordsCompetitors: "Competitors",
  wordsCompetitorsDescription:
    "Keep us up-to-date with relevant info about your organization's competitors",
  websites: "Websites",
  taskResultFeedbackTitle: "How satisfied are you with the result?",
  changeLanguage: "Change Language",
  onboardingCompleteTitle: "Onboarding Complete",
  requiredValidationMessage: "{field} is required",
  greetingGoodMorning: "Good Morning",
  greetingGoodAfternoon: "Good Afternoon",
  greetingGoodEvening: "Good Evening",
  howCanBotHelpToday: "How can I help you today?",
  defaultSuggestionDescription: "Enter the details of your task and let's get started",
  defaultSuggestionTitle: "What do you need help with?",
  scheduleNextTask: "Schedule your next task",
  clearSchedule: "Clear schedule",
  processing: "Processing",
  complete: "Complete",
  wordsInterval: "Interval",
  socialYoutube: "Youtube",
  socialThreads: "Threads",
  freeTrialScheduledTaskInfo:
    "Upgrade your plan to gain access to scheduling features for tasks.",
  wordsDisabled: "disabled",
  freeTrialDaysLeft: "{daysLeft} day left",
  freeTrialDaysLeftPlural: "{daysLeft} days left",
  cancellationPendingBannerTitle: "Subscription Cancellation Pending",
  cancellationPendingBannerDescription:
    "Your subscription is going to be canceled on {cancelAtDate}. Renew your subscription to continue using Marketing Assistant.",
  renew: "Renew",
  addCompetitor: "Add Competitor",
  competitorsUpdated: "Competitors Updated",
  newCompetitorWillBeAddedAfterVerify:
    "New competitor websites will appear once they have been verified.",
  notice: "Notice",
  reset: "Reset",
  fineTuningLabel: "Fine Tuning",
  fineTuningDescription: "Adjust the behavior of the assistant to fit your needs",
  pagesIdentified: "{number} pages identified",
  confirmSocialLinkDeletion: "Are you sure you want to remove this {platform} link?",
  confirmCompetitorDeletion: "Are you sure you want to remove this competitor?",
  suggestions: "Suggestions",
  suggestionsSubHeading: "Here are some suggestions for you",
  noSuggestions: "No suggestions",
  noSuggestionsInfo:
    "Nothing to show yet. Helpful marketing tips tailored for {companyName} are on the way!",
  wordCategory: "Category",
  wordTags: "Tags",
  errorLoadingSuggestions:
    "There was an error loading your suggestions. Please try again",
  wordAccept: "Accept",
  subPages: "Sub pages",
  lastVisited: "Last Visited",
  preferenceSaved: "Preference Saved",
  wordsDecline: "Decline",
  websiteCrawlEventDescription: "Your website will be scanned for relevant content",
  competitorWebsiteCrawlEventDescription:
    "Your competitors' websites will be scanned for relevant content that can be used to improve your content",
  frequencyWithDay: "Runs {frequency} on {day}",
  frequencyDaily: "Runs daily",
  socialMediaCrawlEventDescription:
    "Your social media platforms will be scanned for relevant content.",
  competitorSocialMediaCrawlEventDescription:
    "Your competitors' social media platforms will be scanned for relevant content that can be used to improve your content",
  suggestionsSystemActivityDescription:
    "Your company and competitor data will be analyzed daily to bring you fresh marketing insights!",
  noRecentConversationsTitle: "No recent conversations",
  noRecentConversationsDescription: "Start a conversation to get started",
  addSocialAccount: "Add Social Account",
  accountType: "Account Type",
  wordsDate: "Date",
  wordsTime: "Time",
  myCompany: "My Company",
  websiteDetails: "Website Details",
  noNewContent: "No new content",
  latestContent: "Latest content",
  systemActivities: "System Activities",
  myActivities: "My Activities",
  accountSocialPageTitle: "{platform} Account Details",
  competitorSocialPageTitle: "Competitor {platform} Account Details",
  socialPlatformPosts: "{platform} Posts",
  planUpgradeSuccessMessage: " Plan has been Upgraded Successfully",
  lastPageCrawlFailed: "Last page visit failed",
  basicInformation: "Basic Information",
  suggestionRatingLabel: "How would you rate this suggestion?",
  suggestionRatingFeedback:
    "Thanks for your feedback! It helps us make your insights better!",
  bad: "Bad",
  noSocialPlatformPosts: "No {platform} posts",
  noSocialPlatformPostsDescription:
    "No posts were found at this time. Please check back later.",
  socialPageNotFound: "Sorry, the profile couldn't be found. Please check back later.",
  ratePost: "Rate post",
  usePostContent: "Use post content",
  usePageContent: "Use page content",
  useWebsiteData: "Use website data",
  usePageDate: "Use page date",
  websiteProcessingDescription:
    "The page is processing. Please come back in a minute or 2 to see the results.",
  externalLink: "External link",
  planAndBilling: "Plan & Billing",
  v2InputAreaPlaceholder: "Click here to start typing",
  comingSoon: "Coming Soon",
  socialMedia: "Social Media",
  fineTuning: "Fine Tuning",
  similarToYourWebsite: "Similar to your website",
  numPages: "{number} pages",
  addNew: "Add New",
  lastAccessed: "Last Accessed",
  anywhereToSkip: "Click anywhere to skip",
  hello: "Hello",
  websitePending: "The website is being verified. We will begin processing shortly",
  later: "Later",
  builtWith: "Built with {platform}",
  allPages: "All Pages",
  retryingMessage: "Request sent. We will retry shortly",
  missingPageDataRetry: "Missing data. Revisit page?",
  myAssistant: "My Assistant",
  wordsAccountUpdated: "Account Updated",
  noCompetitors: "No Competitors",
  addCompetitorsDescription: "Add competitors and let's help you get an edge",
  websiteLabel: "Website",
  files: "Files",
  deleteFileTitle: "Delete File",
  fileDeleted: "File Deleted",
  deleteConfirmationMessage: "Are you sure you want to delete {filename}",
  usePageData: "Use Page Data",
  noPages: "No Pages",
  noPagesfound: "No pages found for this website",
  discovered: "Discovered {timeAgo}",
  postDetails: "{platform} post details",
  notifications: "Notifications",
  noNotifications: "No Notifications",
  wordsPost: "Post",
  everything: "Everything",
  company: "Company",
  profile: "Profile",
  wordsAll: "All",
  mailSubject: "Subject",
  mailSidebarInfo: "Send task requests to your assigned email address",
  mailResultInfo: "Once your task is created, the result will be in the response",
  socialAccountSelect: "Select type of social account",
  facebookReel: "Facebook Reel",
  facebookReels: "Facebook Reels",
  mustBeUniqueCompetitor: "Must be a unique competitor",
  resize: "Resize",
  reels: "Reels",
  recentSearches: "Recent Searches",
  foundResults: "Found {number} results",
  images: "Images",
  facebookReelDetails: "Facebook reel details",
  profileDetails: "{platform} account details",
  webpageDetails: "Webpage details",
  wordsResearch: "Research",
  wordsQuery: "Query",
  researchDescription:
    "Provide a query about your company and let's research and let you know how you rank against your competitors, and your general approval rating on the internet.",
  createFirstQuery: "Create your first query",
  newQuery: "New query",
  companyRank: "Company rank",
  competitorRank: "Competitor rank",
  favorableResults: "Favorable results",
  wordsReason: "Reason",
  noReasonValidationError: "At least one reason must be selected",
  wordsPricing: "Pricing",
  workingOnQuery: "Working on your query",
  wordsResults: "Results",
  appearances: "Appearances",
  favorablePercent: "Favorable result percentage",
  errorOccurredTryAgain: "An error occurred. Please try again later.",
  authWordsSignOutDescription: "Signing you out",
  processingPages: "Processing pages",
  accountWebsiteProcessed: "All pages processed for website {url}",
  accountPageProcessed: "Website page, {url}, successfully processed",
  accountLinkedinCompanyProcessed: "Account LinkedIn company discovered",
  accountSocialPostsProcessed: "Account {platform} posts discovered",
  accountSocialProfileProcessed: "Account {platform} profile discovered",
  allSocialProcessed: "All account social platforms processed",
  genericSocialProcessed: "Social account discovered",
  socialPostDiscovered: "Social post discovered",
  pagesCrawledDescription: "{complete} of {total} pages complete",
  competitorWebsiteProcessed: "All pages processed for competitor website {url}",
  competitorLinkedinCompanyProcessed: "Competitor LinkedIn company discovered",
  competitorSocialPostsProcessed: "Competitor {platform} posts discovered",
  competitorSocialProfileProcessed: "Competitor {platform} profile discovered",
  allCompetitorSocialProcessed: "All competitor social platforms processed",
  allDataProcessed: "Account and competitor data discovered and reviewed",
  selectAll: "Select All",
  singleTaskDeletionMessage: "Do you want to delete this task?",
  bulkTaskDeletionMessage: "Do you want to delete these tasks?",
  taskDeletedSuccessful: "task deleted",
  canAddCompetitorMessage: "To add new Competitor, you need to upgrade your plan",
  wordsIncoming: "Incoming",
  wordsOutgoing: "Outgoing",
  featureNotAvailable: "Not available in current plan",
  CreateFacebookContent: "Create Facebook Content",
  CreateTwitterContent: "Create Twitter Content",
  CreateLinkedInPost: "Create LinkedIn Post",
  ComposeEmail: "Compose Email",
  GenerateIdeas: "Generate Ideas",
  CreateFacebookVideoScript: "Create Facebook Video Script",
  CreateContent: "Create Content",
  CreateInstagramPost: "Create Instagram Post",
  AddtextImage: "Add text, images or a URL",
  wordsPrevious: "Previous",
  wordsHelp: "Help",
  regeneratingContent: "Regenerating Content",
  updatePrompt: "Not quite what you expected? Update your prompt and try again.",
  regenerating: "Regenerating",
  onboardingCompleteDescription: "Here's overview of your account information",
  socialAccounts: "Social accounts",
  noSocialAccounts: "No social accounts",
  findHelpTipTitle: "Find help in app",
  findHelpTipText:
    "Look out for icons with a question mark (?) throughout the app. This means that you can get additional information on a topic by clicking on the icon.",
  dragImageTipTitle: "Drag & drop images when creating a task.",
  dragImageTipText:
    "When creating a task, you can drag and drop an image from your computer into the editor to add a visual representation of your task. A picture is worth a thousand words",
  didYouKnow: "Did you know?",
  updateAssistantTitle: "Update your data",
  updateAssistantTipText:
    'You can update all the details that were provided when setting up your assistant. Look for the "Assistant" icon in the sidebar.',
  wordsTasks: "Tasks",
  wordsAssistant: "Assistant",
  getStartedCreateTaskDescription: "Got an idea? Let's create a task.",
  getStartedIntegrationsDescription:
    "Extend your assistant by integrating with your favorite tools.",
  getStartedResearchDescription:
    "Check how your website is performing against competitors across search engines.",
  getStartedScheduleTaskDescription:
    "Want to create tasks on a schedule? Let's start here.",
  getStartedFineTuningDescription:
    "Adjust the behavior of the assistant to fit your needs.",
  getStartedDescription:
    "Welcome to your marketing assistant. Here are a few actions to get you started.",
  getStartedTodosTitle: "To-dos",
  getStartedTodosDescription: "Let's complete your set-up with these final steps.",
  addACompetitor: "Add a competitor",
  addCompetitorDescription: "Add or update the list of competitor websites.",
  addASocialAccount: "Add a social account",
  addSocialAccountDescription: "Add or update your social accounts.",
  likes: "Likes",
  inputTooShort: "Input too short",
  taskCreated: "Task created",
  createInstantTask: "Create an instant task",
  quickTaskFooter: "Create a new task using this result",
  quickTaskInputTip:
    "✨ Tip: You can edit the prompt to match exactly what you want to generate.",
  competitorInfo: "Competitor information",
  noPageSummary: "No page summary available at the moment. Please check back later",
  latestUpdates: "Latest updates",
  updateSummary: "Update summary",
  postUpdatesTitle: "Post updates",
  postUpdatesDescription:
    "Here are the summaries of the latest posts from your competitor's social accounts",
  noUpdatesSinceCheck: "No new updates since the last check",
  metrics: "Metrics",
  metricsDescription:
    "How this website has been performing over the past year(compared to yours)",
  unknownCategory: "Unknown category",
  cost: "Cost",
  traffic: "Traffic",
  keywords: "Keywords",
  keywordsAndRank: "Keywords & Rank",
  adCopies: "Ad copies",
  nothingToDisplay: "Nothing to display at the moment",
  sponsored: "Sponsored",
  profileImageQuality: "Profile images",
  okay: "Okay",
  great: "Great",
  excellent: "Excellent",
  numberOfItemsSingle: "{number} item",
  numberOfItems: "{number} items",
  settingsUsageTitle: "Usage for {currentMonthName}",
  postedTwitter: "Posted on Twitter",
  errorPostingToTwitter: "Error posting to Twitter",
  accountName: "Account Name",
  noRepost: "Tweet Already Posted",
  postLinkedIn: "Post to LinkedIn",
  postToTwitter: "Post to Twitter",
  postedLinkedIn: "Posted to LinkedIn",
  alreadyPosted: "Already Posted",
  errorPostingToLinkedIn: "Error to LinkedIn to LinkedIn",
  header: "Header",
  comparePlans: "Compare",
  comparePlanLimits: "Current Plan Limits",
  planFeatures: "Plan Features",
  plan: "Plan",
  premium: "Premium",
  starter: "Starter",
  business: "Business",
  availableIntegrations: "Available integrations",
  taskScheduling: "Task Scheduling",
  maxCompetitors: "Maximum competitors",
  availableResearchQueries: "Available research queries",
  maxSocialAccounts: "Maximum social accounts",
  numberOfUsers: "Number of users",
  socialVisitFrequency: "Frequency of social account visits",
  websiteVisitFrequency: "Frequency of webpage visits",
  competitorWebsiteVisitFrequency: "Frequency of competitor account visits",
  billingPageDescription: "Get information about your plan, features and usage.",
  authUrlError: "Failed to get authentication URL",
  codeStateError: "OAuth connection failed - missing code or state",
  twitterConnectionError: "Twitter connection failed",
  type: "Type",
  contactSaved: "Contact saved",
  addContact: "Add contact",
  newContact: "New contact",
  newContactDescription: "Add a new contact to your account",
  contactDeleted: "Contact deleted",
  contactDeletionConfirmation: "Are you sure you want to delete {contact}?",
  contacts: "Contacts",
  contactSubHeading: "Add, update and manage your email contacts",
  contactEmptyStateText: "Select a contact to view or update their details",
  templates: "Templates",
  templateEmptyStateText: "Select a template to preview or set as default",
  contactsUploaded: "Contacts uploaded and processing",
  unsupportedFileFormat: "Unsupported file format",
  logoUploaded: "Logo uploaded",
  emailSent: "Email sent",
  emailNotSent: "Failed to send email, please try again",
  created: "Created",
  status: "Status",
  canceled: "Canceled",
  failed: "Failed",
  content: "Content",
  noNewTransaction: "No new transaction found",
  errorConnecting: "Error connecting to {platform}",
  popupClose: "popup Close",
  noAccountInfo: "No account info",
  inbox: "Inbox",
  template1Name: "New York",
  template2Name: "Hawaii",
  template1Description: "Give your emails a personality",
  template2Description: "Simple template with centered content",
  emailSetupCompleteDescription:
    "You can start using the app to send marketing emails to your contacts.",
  newMail: "New Mail",
  send: "Send",
  message: "Message",
  bulkUpload: "Bulk Upload",
  recipient: "Recipient",
  setUpEmailAlias: "Setup your alias",
  setUpEmailAliasDescription:
    "This is the address through which customers will receive your emails",
  addFirstContact: "Add your first contact",
  contactAddedToACcount: "Contact has been added to your account",
  dropCsvFile: "Drop your csv file here",
  addSingleContact: "Add a single contact",
  yourEmail: "Your email",
  yourEmailPlaceholder: "enter your email",
  recipientEmailPlaceholder: "enter the recipient email",
  emailSetupCustomDescription:
    "Add your unique email address that will be used by the platform to send your emails",
  emailSetupVerificationDescription:
    "Verification email sent. Check your mail and click the link in the email to verify. When done, come back and click the “Continue” button below.",
  emailSetupIntroTitle: "Let's get you set up",
  emailSetupIntroDescription:
    "You can use your own marketing email or go with the simple option and use a marketingassistant email. Click continue when you're ready.",
  emailSetupTypeTitle: "Setup type",
  emailSetupTypeDescription:
    "How would you want the emails to be sent with your own domain or marketingassistant.com",
  wordSimple: "Simple",
  wordCustom: "Custom",
  emailSetupSimpleDescription:
    "add an alias and send emails using your_alias@marketingassistant.com",
  sendTestEmailTitle: "Send a test email",
  sendTestEmailDescription:
    "Let's test your new email integration. Enter an email address (different from the one you've configured) to send a test mail.",
  skip: "Skip",
  selectFile: "Select file",
  activeTemplateDescription: "Your emails will look like this template when sent",
  useTemplate: "Use template",
  changeLogo: "Change logo",
  selectLogo: "Select logo",
  dropLogo: "Drop your logo here",
  activeTemplateConfirmation:
    "are you sure you want to set {activeTemplateName} as the default template?",
  templateListDescription: "Choose how your outgoing emails look and feel",
  emailMarketingModalTitle: "Introducing Email Outreach",
  emailMarketingModalDescription:
    "Reach out to your customers from right here in Marketing Assistant",
  emailMarketingModalFeature1:
    "Use a marketingassistant.com alias or your own custom email",
  emailMarketingModalFeature2: "Add and manage contacts (we support bulk uploads)",
  emailMarketingModalFeature3: "Choose a template that fits your style",
  emailMarketingModalFeature4: "Customize emails with your logo",
  emailMarketingModalSubText: "And many more features coming soon!",
  new: "New",
  researchTourStep1Description:
    "Provide a query about your company and let's research and let you know how you rank against your competitors, and your general approval rating on the internet.",
  researchTourStep2Description: "Enter your query and select your reasons",
  fineTuningTourText:
    "Adjust the behavior of your assistant, to match our business needs",
  taskTourStep1Title: "Create Your First Task",
  taskTourStep1Description:
    "Begin your path to streamlined marketing with Marketing Assistant. Simplify your efforts, save time, and create with ease. Ready to take the first step?",
  taskTourStep2Title: "Choose Your Marketing Focus",
  taskTourStep2Description:
    " Select a task category to tailor Marketing Assistant to your specific needs.",
  taskTourStep2SubDescription: "Select a category below.",
  taskTourStep3Title: "Select Your Task",
  taskTourStep3Description:
    "Zero in on what matters most for your marketing efforts today. Marketing Assistant simplifies your marketing without sacrificing results. Let's get to work.",
  taskTourStep4Title: "Let’s Start Creating!",
  taskTourStep4Description:
    "You're one step closer to reclaiming your schedule with Marketing Assistant's time-saving magic.",
  taskTourTips: "Tips to get the most from your results:",
  detailMatters: "Detail Mattters",
  detailMattersDescription: "The more precise your input,the more tailored your output.",
  planAhead: "Plan Ahead",
  planAheadDescription:
    "Schedule tasks and have results delivered straight to your inbox.",
  refineRepeat: "Refine & Repeat",
  refineRepeatDescription:
    "Each task helps Marketing Assistant learn and adapt to your needs for continually improved results.",
  streamline: "Streamline",
  streamlineDescription:
    "Combine different tasks for a seamless marketing strategy that works effectively in the background.",
  integrationTourStep1Title:
    "Extend your assistant by integrating with your favorite tools",
  integrationTourSep1SubTitle: "Social Media Integration",
  integrationTourStep1Description: "Post directly to Facebook and Instagram",
  tourContinue: "Click on the continue to setup your integration",
  sendNow: "Send Now",
  sendLater: "Send Later",
  dateAndTime: "Date and Time",
  invalidScheduleTime: "Invalid schedule time",
  emailScheduled: "Email scheduled. It will be sent at the scheduled time",
  processingLogo: "Processing new logo",
  processingContacts: "Processing new contacts",
  sendingEmail: "Processing email",
  sendingEmails: "Processing emails",
  sendingEmailAllContacts: "Sending email to all contacts",
  campaigns: "Campaigns",
  add: "Add",
  addGoal: "Add Goal",
  companyGoal: "Company Goal",
  strategicObjectives: "Strategic Objectives",
  strategicObjective: "Strategic Objective",
  addObjectives: "Add Objective",
  coreMarketingChannels: "Core Marketing Channels",
  coreMarketingChannel: "Core Marketing Channel",
  addChannel: "Channel",
  culturalConsiderations: "Cultural Considerations",
  culturalConsideration: "Cultural Consideration",
  addCulturalConsiderations: "Add Consideration",
  customerPainPoints: "Customer Pain Points",
  customerPainPoint: "customer Pain Point",
  addPoint: "Add Point",
  industryCategory: "Industry Category",
  geographicLocation: "Geographic Location",
  productsAndService: "Products And Service",
  visionAndMission: "Vision And Mission",
  brandPositioning: "Brand Positioning",
  politicalAffiliation: "Political Affiliation",
  politicalNonAffiliation: "Political Non Affiliation",
  brandIdentityTone: "Brand Identity Tone",
  selectTone: "Select Tone",
  targetAudienceOverview: "Target Audience Overview",
  targetAudienceDemographics: "Target Audience Demographics",
  gender: "Gender",
  selectGender: "Select Gender",
  education: "Education",
  ageFloor: "Minimum Age",
  ageCeiling: "Maximum Age",
  incomeFloor: "Minimum Income",
  incomeCeiling: "Maximum Income",
  companyDetails: "Company Details",
  campaignDetails: "Campaign Details",
  companyGoals: "Company Goals",
  editCampaign: "Edit Campaign",
  upToDate: "Already up to date",
  playful: "Playful",
  neutral: "Neutral",
  serious: "Serious",
  campaign: "Campaign",
  wordDefault: "Default",
  createCampaign: "Create Campaign",
  noCampaigns: "No Campaigns",
  noCampaignsDescription: "This account has no campaigns yet.",
  addFilter: "Add a Filter",
  addField: "Add a Field",
  campaignSaved: "Campaign saved",
  campaignSaveFailed: "Failed to save campaign",
  addFilterAlert: "Please add at least one filter",
  nameOfCampaign: "Name of the Campaign",
  campaignNamePlaceholder: "Enter the name of the campaign",
  accountDataPointDescription:
    "This section allows you to configure the core data points that fuel our content generation engine. The more information you provide, the more accurate and relevant the content is to your brand.",
  chooseTargetAudience: "Choose the target audience",
  deleteCampaignConfirmation: "Are you sure you want to delete this campaign?",
  campaignDeleteFailed: "Failed to delete campaign",
  campaignDeleteSuccess: "Campaign deleted",
  campaignDeleteTitle: "Delete Campaign",
  shareComingSoon: "Coming Soon",
};
