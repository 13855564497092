<template>
  <div
    class="grow flex flex-col items-center justify-center absolute w-full top-16"
    :class="v2LayoutEnabled ? '' : 'max-w-[1072px] mx-auto'"
  >
    <div class="py-4 w-full mb-auto">
      <ProgressBar :value="progress" :show-value="false" style="height: 5px" />
    </div>
    <TipsWrapper class="mt-4 opacity-0 animate-[fade-in_0.5s_0.2s_forwards] mb-auto" />
  </div>
</template>

<script setup lang="ts">
import TipsWrapper from "../tips/TipsWrapper.vue";
import ProgressBar from "primevue/progressbar";
defineProps<{
  description?: string;
  v2LayoutEnabled: boolean;
}>();
usePauseScroll();

const { progress } = useFakeProgress({
  maxValue: 99,
  increments: 20,
  minValue: 20,
  interval: 700,
});
</script>
<style scoped>
.pulse {
  animation: pulse 2.8s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
