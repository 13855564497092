<template>
  <svg
    width="269"
    height="220"
    viewBox="0 0 269 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="3" fill="#D1D5DB" />
    <rect y="44" width="28" height="28" rx="3" fill="#D1D5DB" />
    <rect y="88" width="28" height="28" rx="3" fill="#D1D5DB" />
    <g clip-path="url(#clip0_32_415)">
      <mask
        id="mask0_32_415"
        style="mask-type: luminance"
        maskUnits="userSpaceOnUse"
        x="0"
        y="132"
        width="28"
        height="28"
      >
        <path
          d="M22.1667 136.667H5.83341C5.524 136.667 5.22725 136.79 5.00846 137.008C4.78966 137.227 4.66675 137.524 4.66675 137.833V154.167C4.66675 154.476 4.78966 154.773 5.00846 154.992C5.22725 155.21 5.524 155.333 5.83341 155.333H22.1667C22.4762 155.333 22.7729 155.21 22.9917 154.992C23.2105 154.773 23.3334 154.476 23.3334 154.167V137.833C23.3334 137.524 23.2105 137.227 22.9917 137.008C22.7729 136.79 22.4762 136.667 22.1667 136.667Z"
          fill="#555555"
          stroke="white"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M17.5 142.5H10.5V149.5H17.5V142.5Z"
          fill="#555555"
          stroke="white"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M8.69751 133.167V136.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.69751 155.333V158.833"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 133.167V136.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 155.333V158.833"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3025 133.167V136.667"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.3025 155.333V158.833"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.16675 140.698H4.66675"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.3335 140.698H26.8335"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.16675 146H4.66675"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.3335 146H26.8335"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.16675 151.303H4.66675"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.3335 151.303H26.8335"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </mask>
      <g mask="url(#mask0_32_415)">
        <path d="M0 132H28V160H0V132Z" fill="#6B7280" />
      </g>
    </g>
    <rect y="176" width="28" height="28" rx="3" fill="#D1D5DB" />
    <path
      d="M265.191 71.9698C276.844 72.6116 204.404 179.139 62.1763 146.571"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-dasharray="4 4"
    />
    <path
      d="M63.0232 152.615L58.7944 146.463L63.6022 141.276"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-linecap="round"
    />
    <defs>
      <clipPath id="clip0_32_415">
        <rect width="28" height="28" fill="white" transform="translate(0 132)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
