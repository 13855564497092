import type { SocialPlatform } from "@/types/account";
import { GlobeAltIcon } from "@heroicons/vue/24/outline";

export function getSocialIcon(platform: SocialPlatform) {
  switch (platform) {
    case "facebook":
      return defineAsyncComponent(() => import("@/components/icons/FacebookIcon.vue"));
    case "twitter":
      return defineAsyncComponent(() => import("@/components/icons/TwitterIcon.vue"));
    case "instagram":
      return defineAsyncComponent(() => import("@/components/icons/InstagramIcon.vue"));
    case "linkedin":
      return defineAsyncComponent(() => import("@/components/icons/LinkedinIcon.vue"));
    case "youtube":
      return defineAsyncComponent(() => import("@/components/icons/YoutubeIcon.vue"));
    case "threads":
      return defineAsyncComponent(() => import("@/components/icons/ThreadsIcon.vue"));
    case "tiktok":
      return defineAsyncComponent(() => import("@/components/icons/TiktokIcon.vue"));
    default:
      return GlobeAltIcon;
  }
}

export const socialPlatformDropdownOptions: { label: string; value: SocialPlatform }[] = [
  {
    label: "Twitter",
    value: "twitter",
  },
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "YouTube",
    value: "youtube",
  },
  {
    label: "Threads",
    value: "threads",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
];

export const socialPlatformArr = socialPlatformDropdownOptions.map(p => p.value);

export const socialProfileKeyArr: `${SocialPlatform}-profile`[] = socialPlatformArr.map(
  i => `${i}-profile` as `${SocialPlatform}-profile`
);
