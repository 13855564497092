import type { FIleUploadType } from "@/types/formData";
import { ulid } from "ulid";
import type { MaybeRef } from "vue";

type S3UploaderConfig = {
  mimeTypes?: string[];
  uploadType?: FIleUploadType;
};

type UploadState = ReturnType<typeof uploadSingleFile>;

export function useS3Uploader(_config?: MaybeRef<S3UploaderConfig>) {
  const queryClient = useQueryClient();
  const config = computed(() => unref(_config) || {});
  const { t } = withI18nHelpers();

  const uploadState = ref<UploadState[]>([]);
  const uploadRecently = ref<UploadState[]>([]);
  const error = ref<string>();

  function onFileInputChange(e: Event) {
    const fileList = (e.target as HTMLInputElement).files;
    handleFileInput(fileList);
  }

  function maybeValidateMimeTypes(files: File[]) {
    if (!config.value.mimeTypes) return true;
    return files.every(
      file =>
        !config.value.mimeTypes ||
        (file instanceof File && config.value.mimeTypes.includes(file.type))
    );
  }

  function handleFileInput(fileList: FileList | File[] | null) {
    if (!fileList) return;
    error.value = "";
    if (fileList instanceof FileList) {
      const areMimeTypesValid = maybeValidateMimeTypes(Array.from(fileList));
      if (!areMimeTypesValid) {
        error.value = t("unsupportedFileFormat");
        return;
      }
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        const id = ulid();
        const uploader = uploadSingleFile(id);
        // @ts-ignore ts is dumb and can't figure out `uploader` = the return type of `uploadSingleFile`
        uploadState.value.push(uploader);
        uploader.upload(file, config.value.uploadType);
      }
      return;
    }
    const files = Array.isArray(fileList) ? fileList : [fileList];
    const areMimeTypesValid = maybeValidateMimeTypes(Array.from(files));
    if (!areMimeTypesValid) {
      error.value = t("unsupportedFileFormat");
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file instanceof File) {
        const id = ulid();
        const uploader = uploadSingleFile(id);
        // @ts-ignore
        uploadState.value.push(uploader);
        uploader.upload(file, config.value.uploadType);
      }
    }
  }

  const isUploading = computed(() => {
    const uploading = uploadState.value.find(a => a.state.uploading);
    const preparing = uploadState.value.find(a => a.state.preparing);
    return !!uploading || !!preparing;
  });

  const uploadProgress = computed(() => {
    const uploading = uploadState.value.map(a => a.state.progress);
    return +(
      uploading.reduce((acc, current) => acc + current, 0) / uploading.length
    ).toFixed(1);
  });

  watch(uploadState.value, r => {
    const completed = r.filter(e => e.state.success).map(a => a.id);

    if (completed.length) {
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
      queryClient.invalidateQueries({
        queryKey: ["files", "list"],
      });

      uploadRecently.value = [...uploadState.value.filter(a => completed.includes(a.id))];
      uploadState.value = uploadState.value.filter(a => !completed.includes(a.id));
    }
  });

  function clearState() {
    if (isUploading.value) return;
    uploadState.value = [];
    uploadRecently.value = [];
  }

  return {
    uploadState,
    error,
    clearState,
    uploadRecently,
    isUploading,
    uploadProgress,
    onFileInputChange,
    handleFileInput,
  };
}
