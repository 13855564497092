export const RouteNames = {
  LOGOUT: Symbol("logout"),
  INITIALIZE: Symbol("initialize"),
  LOGIN: Symbol("login"),
  COMPONENT_PAGE: Symbol("componentPage"),
  PLANS: Symbol("plans"),
  ONBOARDING: Symbol("onboarding"),
  VERIFY_ACCOUNT: Symbol("verify-account"),
  PAYMENT_FAILED: Symbol("payment-failed"),
  REGISTER: Symbol("register"),
  TASK_LIST: Symbol("task-list"),
  SINGLE_TASK: Symbol("single-task"),
  CREATE_TASK_NEW: Symbol("create-task-new"),
  SEARCH_TASKS: Symbol("search-tasks"),
  CREATE_TASK: Symbol("create-task"),
  SINGLE_TASK_CATEGORY: Symbol("single-task-category"),
  SINGLE_TASK_TYPE: Symbol("single-task-type"),
  SCHEDULED_ACTIVITIES: Symbol("scheduled-activities"),
  SINGLE_SCHEDULED_ACTIVITY: Symbol("single-scheduled-activity"),
  BRAIN: Symbol("brain"),
  SINGLE_RESEARCH_RESULT: Symbol("single-research-result"),
  ASSISTANT_WEBSITE: Symbol("assistant-website"),
  ASSISTANT_PLATFORM: Symbol("assistant-platform"),
  ASSISTANT_PLATFORM_DETAILS: Symbol("assistant-platform-details"),
  SETTINGS: Symbol("settings"),
  SETTING: Symbol("Settings"),
  CONNECTED_APPS: Symbol("Connected Apps"),
  INTEGRATIONS: Symbol("Integrations"),
  ACCOUNT_USERS: Symbol("Account Users"),
  NOTIFICATIONS: Symbol("notifications"),
  SETTINGS_NOTIFICATIONS: Symbol("settings-notifications"),
  SETTINGS_FILTERS: Symbol("settings-filters"),
  BILLING: Symbol("billing"),
  TENANT_HOME: Symbol("tenant-home"),
  TENANT_ACCOUNTS: Symbol("tenant-accounts"),
  TENANT_WELCOME: Symbol("tenant-welcome"),
  TENANT_SETTINGS: Symbol("tenant-settings"),
  TENANT_ACCOUNT_USERS: Symbol("tenant-account-users"),
  TENANT_SETTINGS_REDIRECT: Symbol("tenant-settings-redirect"),
  TENANT_ACCOUNT_SETTINGS: Symbol("tenant-account-settings"),
  TENANT_SETTINGS_REDIRECT2: Symbol("tenant-settings-redirect2"),
  THIRD_PARTY: Symbol("third-party"),
  RESTRICTED_VIEW: Symbol("restricted"),
  WELCOME: Symbol("welcome"),
  HOME: Symbol("home"),
  SUGGESTIONS: Symbol("suggestions"),
  ERROR: Symbol("error"),
  MAIN: Symbol("main"),
  NOT_FOUND: Symbol("NotFound"),
  EMAILS: Symbol("emails"),
  EMAIL_INBOX: Symbol("email-inbox"),
  EMAIL_CONTACTS: Symbol("email-contacts"),
  EMAIL_SINGLE_CONTACT: Symbol("email-single-contact"),
  EMAIL_ADD_CONTACT: Symbol("email-add-contact"),
  EMAIL_GENERAL_COMPOSE: Symbol("email-general-compose"),
  EMAIL_CONTACT_COMPOSE: Symbol("email-contact-compose"),
  EMAIL_TEMPLATES: Symbol("email-templates"),
  EMAIL_SINGLE_TEMPLATE: Symbol("email-single-template"),
  EMAIL_SETUP: Symbol("email-setup"),
  SINGLE_SUGGESTION: Symbol("single-suggestion"),
};
