import type { ITaskBase, ScheduledTaskResponse } from "@/types/task";
import type { UseInfiniteQueryReturnType } from "@tanstack/vue-query";
import { get } from "lodash-es";

type TaskListResponse<T = ITaskBase> = { items: T[]; next?: string };

export const taskListLimit = ref(30);
export function useListTasks(
  enabled?: globalThis.Ref<boolean> | boolean | globalThis.ComputedRef<boolean>
): UseInfiniteQueryReturnType<ITaskBase[], Error> {
  const query = useInfiniteQuery({
    queryKey: ["tasks", { limit: taskListLimit }],
    queryFn: async ({ pageParam }: { pageParam?: string } = {}) => {
      const response = await useGetRequest<TaskListResponse>("tasks", {
        params: {
          nextQuery: pageParam,
          __limit: taskListLimit.value,
        },
      });
      return get(response, "data", null);
    },
    select: (data: Record<any, any>) => {
      return data?.pages?.map((p: Record<any, any>) => p.items)?.flat() || [];
    },
    initialPageParam: undefined,
    placeholderData: { pages: [{ items: [], next: "" }], pageParams: [] },
    getNextPageParam: (res: Record<any, any>) => {
      if (!res?.next) return null;

      const query = res.next?.split("?") || [];

      const searchParams = new URLSearchParams(query.slice(1).join("?"));
      const nextQuery = searchParams.get("nextQuery");

      if (query[0] !== "tasks" || !nextQuery) {
        return null;
      }
      return nextQuery;
    },
    enabled,
    refetchOnMount: "always",
    staleTime: 1 * MINUTE,
  });

  return query;
}

export function useScheduledTasks(
  enabled?: globalThis.Ref<boolean> | boolean | globalThis.ComputedRef<boolean>
): UseInfiniteQueryReturnType<ScheduledTaskResponse[], Error> {
  const query = useInfiniteQuery({
    queryKey: ["scheduled-tasks"],
    queryFn: async ({ pageParam }: { pageParam?: string } = {}) => {
      const response = await useGetRequest<TaskListResponse<ScheduledTaskResponse>>(
        "/scheduled-tasks",
        {
          params: {
            nextQuery: pageParam,
            __limit: taskListLimit.value,
          },
        }
      );
      return get(response, "data", null);
    },
    placeholderData: { pages: [{ items: [], next: "" }], pageParams: [] },
    initialPageParam: undefined,
    getNextPageParam: (res: Record<any, any>) => {
      if (!res?.next) return null;
      const query = res.next?.split("?") || [];

      const searchParams = new URLSearchParams(query.slice(1).join("?"));
      const nextQuery = searchParams.get("nextQuery");

      if (!nextQuery) {
        return null;
      }
      return nextQuery;
    },
    select: (data: Record<any, any>) => {
      return data?.pages?.map((p: Record<any, any>) => p.items)?.flat() || [];
    },
    enabled,
    refetchOnMount: "always",
    staleTime: 1 * MINUTE,
  });

  return query;
}
